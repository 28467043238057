.App {
  background-color: #41444b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
  position: relative;
}

.info {
  color: smokewhite;
  font-size: small;
  position: absolute;
  bottom: 0;
}

a {
  color: orange;
  text-decoration: none;
}
